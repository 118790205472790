<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link
        :to="{ name:'landing' }"
        class="brand-logo"
      >
        <vuexy-logo />
        <h2 class="brand-text">
          BEESUMMER<h2 />
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            src="@/assets/images/pages/signup-dark.svg"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            BEE SUMMER 夏蜂 {{ $t('starts here') }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('experience a fresh way to manage money and stores') }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- user name -->
              <b-form-group
                :label="$t('Username')"
                label-for="login-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="username"
                  rules="required"
                >
                  <b-form-input
                    id="login-name"
                    v-model="userName"
                    :state="errors.length > 0 ? false:null"
                    name="login-name"
                    placeholder="John Smith"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{ $t('password') }}</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="agree"
                  v-model="status"
                  name="checkbox-1"
                >
                  {{ $t('I agree to') }}
                  <b-link
                    target="_blank"
                    :to="{ name: 'terms'}"
                  >
                    {{ $t('terms') }}
                  </b-link> {{ $t('and') }} <b-link
                    target="_blank"
                    :to="{ name: 'privacy' }"
                  >
                    {{ $t('privacy policy') }}
                  </b-link>
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                {{ $t('Register') }}
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>{{ $t('Already have an account?') }}</span>
            <b-link :to="{name:'login'}">
              <span>&nbsp;{{ $t('Sign in instead') }}</span>
            </b-link>
          </b-card-text>

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">
              {{ $t('or') }}
            </div>
          </div> -->

          <!-- social buttons -->
          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
          </div> -->
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import VuexyLogo from '@core/layouts/components/Logo.vue'
// import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import svgToMiniDataURI from 'mini-svg-data-uri'
import store from '@/store'
import {
  auth, usersCollection, firebase,
} from '../../firebase'

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      userName: '',
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          auth
            .doCreateUserWithEmailAndPassword(this.userEmail, this.password)
            .then(async res => {
              const log = {
                type: 'access',
                action: 'signup',
                actionId: res.user?.uid,
              }
              store.dispatch('logUser', log)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('Success Logged in'),
                  icon: 'StarIcon',
                  variant: 'success',
                },
              })
              let avatar = ''
              await fetch(`https://api.dicebear.com/7.x/micah/svg?seed=${JSON.stringify(Math.floor(Math.random() * 1000000000))}`, { mode: 'cors' })
                .then(respond => respond.text())
                .then(svg => {
                  avatar = svgToMiniDataURI(svg)
                })

              const fileName = `usersAvatar/${res.user.uid}.svg`
              const storageRef = firebase.storage.ref()
              const mountainsRef = storageRef.child(fileName)

              await mountainsRef.putString(avatar, 'data_url')
              const urlRef = storageRef.child(fileName)
              const tokenUrl = await urlRef.getDownloadURL().then(url => url)
              auth.doPhotoURLChange(tokenUrl)

              usersCollection.doc(res.user.uid).set({
                uid: res.user?.uid,
                email: this.userEmail.toLowerCase(),
                displayName: this.userName,
                roles: {},
                status: '',
                photoURL: tokenUrl,
                stores: [],
                settings: {},
                isDeleted: '',
                createdAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
                updatedAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
              })
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Success Signed up',
                      icon: 'StarIcon',
                      variant: 'success',
                    },
                  })
                  this.$router.push({ name: 'store-connect' })
                })
                .catch(error => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: error.message || 'Failed Sign up',
                      icon: 'AlertTriangleIcon',
                      variant: 'error',
                    },
                  })
                })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.message || 'Failed Sign up',
                  icon: 'AlertTriangleIcon',
                  variant: 'error',
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
